/**
 * @generated SignedSource<<1655e8956acd4b61c9127a084e00e4ed>>
 * @relayHash 8f31ab22ec19b063329e1b1fd1db6f00
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/480.0.0-2024-09-16T11:47:45.928Z/useAddItemToCart_userCartItemAddMutation

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CartType = "CHECKOUT" | "SAVE_FOR_LATER" | "%future added value";
export type UserCartItemAddToCartInput = {
  clientMutationId?: string | null;
  itemId: string;
  quantity: number;
  skuId: string;
  type: CartType;
};
export type useAddItemToCart_userCartItemAddMutation$variables = {
  input: UserCartItemAddToCartInput;
};
export type useAddItemToCart_userCartItemAddMutation$data = {
  readonly userCartItemAddToCart: {
    readonly clientMutationId: string | null;
  } | null;
};
export type useAddItemToCart_userCartItemAddMutation = {
  response: useAddItemToCart_userCartItemAddMutation$data;
  variables: useAddItemToCart_userCartItemAddMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UserCartItemAddToCartPayload",
    "kind": "LinkedField",
    "name": "userCartItemAddToCart",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useAddItemToCart_userCartItemAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useAddItemToCart_userCartItemAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "ferrum/480.0.0-2024-09-16T11:47:45.928Z/useAddItemToCart_userCartItemAddMutation",
    "metadata": {},
    "name": "useAddItemToCart_userCartItemAddMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "a20dc7492d3b23878a7653904fdb4a9b";

export default node;
